<template>
  <header
    class="fixed left-0 top-0 z-20 w-full bg-blue p-3"
    :class="{ 'bg-transparent': $route.name === 'map' }"
  >
    <nav class="flex flex-col rounded-md bg-white shadow-lg md:flex-row">
      <div class="flex">
        <router-link custom v-slot="{ navigate }" :to="{ name: 'dashboard' }">
          <h1
            :style="{ backgroundImage: `url(${logoUrl})` }"
            @click="navigate"
            class="m-2 h-10 w-10 cursor-pointer bg-contain bg-no-repeat"
          >
            <i class="invisible">CDA</i>
          </h1>
        </router-link>

        <button @click="toggleMenu" class="ml-auto px-3 md:hidden">
          <x-mark-icon v-if="showMenu" class="h-6 w-6" />
          <bars-3-icon v-else class="h-6 w-6" />
        </button>
      </div>

      <div
        class="hidden w-full overflow-auto md:flex"
        :class="{ '!flex': showMenu }"
      >
        <ul class="flex w-full flex-col gap-1 p-2 md:flex-row">
          <li
            :key="item.path"
            :class="item.path.substring(1, 99)"
            class="flex flex-col md:flex-row md:items-center md:last-of-type:ml-auto"
            v-for="item in authorizedItems"
          >
            <a v-if="item.action" @click="item.action">
              {{ item.title }}
            </a>
            <router-link
              custom
              v-else
              :to="item.path"
              v-slot="{ href, route, navigate }"
              ><a
                :href="href"
                @click="navigate"
                :class="{
                  'router-link-active':
                    ($route.path.startsWith(route.path) &&
                      route.path !== '/') ||
                    (route.path === '/' && $route.name === 'dashboard'),
                }"
                >{{ item.title }}</a
              ></router-link
            >

            <ul
              v-if="item.children"
              class="mt-1 hidden flex-col gap-1 pl-4 md:m-0 md:flex-row md:bg-neutral-100 md:p-1 md:pl-1"
            >
              <li :key="child.path" v-for="child in item.children">
                <router-link custom :to="child.path" v-slot="{ href, navigate }"
                  ><a
                    :href="href"
                    @click="navigate"
                    :class="{
                      'router-link-active':
                        $route.path.startsWith(child.path) &&
                        child.path !== '/',
                    }"
                    >{{ child.title }}</a
                  ></router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script setup>
  import { hasRoles, hasAnyRoles, hasMemberships } from '@/mixins/people'
  import { computed, watch, ref } from 'vue'
  import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/solid'
  import logoUrl from '@/assets/cda.png'
  import { useRoute } from 'vue-router'

  const emit = defineEmits(['logout'])

  const props = defineProps({
    me: Object,
    settings: Object,
  })

  const showMenu = ref(false)
  const route = useRoute()

  watch(route, () => {
    showMenu.value = false
  })

  const authorizedItems = computed(() => {
    var items = [{ path: '/', title: 'Dashboard' }]

    if (hasRoles(props.me, ['Penningmeester', 'Voorzitter'])) {
      items.push({
        path: `/penningmeester/jaarrekening/${props.settings.yearStatementYear}`,
        title: 'Jaarrekening',
      })
    }

    if (props.me.isAdmin || hasRoles(props.me, ['Redacteur Appèl'])) {
      items.push({
        path: '/appel',
        title: 'Appèl',
        children: [
          {
            path: '/appel/overzicht',
            title: 'Berichten',
          },
          {
            path: '/appel/beheer',
            title: 'Beheer',
          },
        ],
      })
    } else if (hasMemberships(props.me, ['BSV'])) {
      items.push({
        path: '/appel',
        title: 'Appèl',
      })
    }

    if (
      hasRoles(props.me, [
        'Secretaris',
        'Voorzitter',
        'Administrateur ledenadm.',
        'Campagneleider',
        'Steunpuntmedewerker',
        'Vicevoorzitter',
        'Secretaris CDJA-afdeling',
        'Bestuurslid ledenzorg',
      ])
    ) {
      items.push({
        path: '/afdeling',
        title: 'Afdeling',
        children: [
          {
            path: '/afdeling/dashboard',
            title: 'Dashboard',
          },
          {
            path: '/afdeling/leden',
            title: 'Leden',
          },
        ],
      })
    }

    if (hasRoles(props.me, ['HRM-voorzitter'])) {
      items.push({
        path: '/afdeling/leden',
        title: 'Leden',
      })
    }

    if (props.me.isVacancyCie) {
      items.push({
        path: '/sollicitaties',
        title: 'Sollicitaties',
      })
    }

    if (hasRoles(props.me, ['Campagneleider', 'Campagneteam lid'])) {
      items.push({
        path: '/campagne',
        title: 'Campagne',
        children: [
          {
            path: '/campagne/kaart',
            title: 'Kaart',
          },
          {
            path: '/campagne/analyse',
            title: 'Analyse',
          },
          {
            path: '/campagne/uitslagen',
            title: 'Uitslagen',
          },
        ],
      })
    } else if (hasAnyRoles(props.me)) {
      items.push({
        path: '/campagne',
        title: 'Campagne',
        children: [
          {
            path: '/campagne/kaart',
            title: 'Kaart',
          },
          {
            path: '/campagne/uitslagen',
            title: 'Uitslagen',
          },
        ],
      })
    }

    items.push({
      path: '/bibliotheek',
      title: 'Bibliotheek',
    })

    items.push({
      path: '/uitloggen',
      action: logout,
      title: 'Uitloggen',
    })

    return items
  })

  const logout = () => {
    emit('logout')
  }

  const toggleMenu = () => {
    showMenu.value = !showMenu.value
  }
</script>

<style lang="postcss" scoped>
  a {
    @apply block cursor-pointer rounded-md bg-neutral-200 px-4 py-3 font-bold no-underline;
  }

  .router-link-active {
    @apply bg-blue text-white;
  }

  .router-link-active + ul {
    @apply flex md:-ml-2 md:rounded-r-md md:bg-blue;
  }

  .router-link-active + ul a {
    @apply md:rounded-sm md:bg-transparent md:px-2 md:py-2 md:font-normal md:text-white;
  }

  .router-link-active + ul .router-link-active {
    @apply md:bg-white md:text-black;
  }
</style>
