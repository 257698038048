import { createApp } from 'vue'
import { createClient, defaultPlugins } from 'villus'
import { cookies } from '@/mixins/villus.js'

import * as Sentry from '@sentry/vue'

import App from './App.vue'
import router from './router'

import FloatingVue from 'floating-vue'
import VueLazyLoad from 'vue3-lazyload'
import ResizeTextarea from 'resize-textarea-vue3'

import dayjs from 'dayjs'
import 'dayjs/locale/nl'
dayjs.locale('nl')

import '@/main.css'
import 'floating-vue/dist/style.css'

const url = import.meta.env.VITE_GRAPHQL_HTTP
const client = createClient({ url, use: [cookies(), ...defaultPlugins()] })

const app = createApp(App)

Sentry.init({
  app,
  environment: import.meta.env.PROD ? 'production' : 'development',
  enabled: !import.meta.env.DEV,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: __APP_VERSION__,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 0.25,
  tracePropagationTargets: ['localhost', 'https://mijn.cda.nl/'],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

app
  .use(router)
  .use(client)
  .use(FloatingVue)
  .use(ResizeTextarea)
  .use(VueLazyLoad)
  .provide('appVersion', __APP_VERSION__)
  .mount('#app')
