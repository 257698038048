<template>
  <div class="login bg-blue">
    <div class="box space-y-2">
      <h1><img :src="logoUrl" class="w-14" /></h1>

      <notice-banner v-if="needsMfa">
        Schakel eerst twee-factor-authenticatie in.<br />
        <pill-button :href="settingsUrl" class="mt-2" color="green" size="sm"
          >Accountinstellingen</pill-button
        >
      </notice-banner>

      <p v-else>
        <button @click="doLogin" autofocus>Inloggen</button>
      </p>
    </div>

    <div
      class="fixed bottom-5 left-2 right-2 z-20 text-center text-xs text-neutral-300/20 sm:bottom-2 sm:left-auto"
    >
      <span
        @click="copyVersion"
        class="inline-flex cursor-pointer items-center gap-1 rounded-md bg-neutral-50/10 px-2 py-1 hover:bg-neutral-50/80 hover:text-blue"
      >
        <check-icon v-if="isCopied" class="base-3 min-w-3" />
        <span class="w-16 truncate">{{ appVersion }}</span>
      </span>
    </div>

    <div
      class="absolute z-20 mx-auto flex w-full justify-center p-2 text-center sm:bottom-2"
    >
      <div
        class="flex max-w-sm flex-col gap-2 rounded-md bg-black/40 p-3 text-xs text-white"
      >
        <p>
          Welkom bij het CDA-ledenportaal: MijnCDA. Hier hebben leden toegang
          tot exclusieve informatie van het CDA.
        </p>

        <p>
          Ben je geen lid? Klik dan op onderstaande button om terug te gaan naar
          de website. Of
          <a href="https://cda.nl/wordlid" class="underline"
            >sluit je aan bij het CDA</a
          >
          om ook toegang te krijgen.
        </p>
      </div>
    </div>

    <div class="background">
      <i v-for="idx in particles" :key="idx" class="particle" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import logoUrl from '@/assets/cda.png'

  import { CheckIcon } from '@heroicons/vue/20/solid'
  import NoticeBanner from '@/components/NoticeBanner.vue'
  import PillButton from '@/components/PillButton.vue'
  import { inject, ref } from 'vue'

  const props = defineProps({
    loginUrl: String,
    needsMfa: Boolean,
    isOry: Boolean,
    settingsUrl: String,
  })

  const appVersion = inject<string>('appVersion')

  const particles = new Array(10)
  const isCopied = ref(false)
  const copyVersion = () => {
    navigator.clipboard.writeText(appVersion || '')
    isCopied.value = true
    setTimeout(() => (isCopied.value = false), 1500)
  }

  const doLogin = () => {
    if (props.loginUrl) {
      document.location.href = props.loginUrl
    }
  }
</script>

<style lang="scss" scoped>
  @use 'sass:math';

  .login {
    height: 100%;
    overflow: auto;
    position: fixed;
    width: 100%;
  }

  .box {
    @include box;

    & {
      left: 50%;
      padding: 20px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 250px;
      z-index: 2;
    }
  }

  div {
    margin: 0;

    p {
      margin: 0;
    }

    button {
      background: $light-gray;
      border-radius: 5px;
      border: 0;
      box-sizing: border-box;
      color: black;
      display: block;
      font-size: 16px;
      font-weight: bold;
      padding: 10px;
      text-align: center;
      text-decoration: none;
      width: 100%;

      &:hover {
        background: $gray;
      }
    }
  }

  .background {
    height: 100%;
    overflow: hidden;
    position: fixed;
    width: 100%;
    z-index: 1;
  }

  $particleSize: 20vmin;
  $animationDuration: 6s;
  $amount: 20;

  .particle {
    width: $particleSize;
    height: $particleSize;
    border-radius: $particleSize;
    backface-visibility: hidden;
    position: absolute;
    animation-name: move;
    animation-duration: $animationDuration;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    $colors: (lighten($blue, 6), lighten($blue, 9), lighten($blue, 12));
    @for $i from 1 through $amount {
      &:nth-child(#{$i}) {
        color: nth($colors, random(length($colors)));
        top: random(100) * 1%;
        left: random(100) * 1%;
        transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
        $blurRadius: (random() + 0.5) * $particleSize * 0.5;
        $x: if(random() > 0.5, -1, 1);
        box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
        $limit: (($animationDuration + 10s) * 10);
        animation-delay: math.random(math.div($limit, 10s)) * -1s;
        $limit2: ($animationDuration * 10);
        animation-duration: (math.random(math.div($limit2, 10s)) * 1s) + 10s;
      }
    }
  }

  @keyframes move {
    100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
    }
  }
</style>
